<template>
    <div class="classData_stuUse">
        <div class="layout_L">
            <div class="stu_App">
                <div class="diamond_title flex-align-center">学生APP学习统计</div>
                <div class="stu_App_graphic1 flex-align-center">
                    <div class="graphic_son" id="stu_App_graphic1" v-if="stu_App_graphicShow1"></div>
                    <PlaceholderScreen v-else></PlaceholderScreen>
                </div>
                <div class="stu_App_graphic2 flex-align-center">
                    <div class="graphic_son" id="stu_App_graphic2" v-if="true"></div>
                    <PlaceholderScreen v-else></PlaceholderScreen>
                </div>
            </div>
            <div class="watched_Top">
                <div class="diamond_title flex-align-center">观看最多的课程Top10</div>
                <div class="watched_Top_graphic flex-align-center">
                    <div class="graphic_son" id="watched_Top_graphic" v-if="Top10_Show"></div>
                    <PlaceholderScreen v-else></PlaceholderScreen>
                </div>
            </div>
        </div>
        <div class="layout_C">
            <div class="Aggregate_data">
                <div class="Aggregate_title">整体数据</div>
                <div class="Aggregate_box flex-align-between">
                    <div class="participants Aggregate_type">
                        <span class="Aggregate_type_name">参与人数</span>
                        <span class="Aggregate_type_num">{{ attend || 0 }}人</span>
                    </div>
                    <div class="learn_lesson Aggregate_type">
                        <span class="Aggregate_type_name">学习课程</span>
                        <span class="Aggregate_type_num">{{ studyCourse || 0 }}次</span>

                    </div>
                    <div class="Evaluation_work Aggregate_type">
                        <span class="Aggregate_type_name">测评作品</span>
                        <span class="Aggregate_type_num">{{ evaluationWork || 0 }}个</span>

                    </div>
                    <div class="non_participants Aggregate_type">
                        <span class="Aggregate_type_name">未参与人数</span>
                        <span class="Aggregate_type_num">{{ nonAttend || 0 }}人</span>
                    </div>
                </div>
            </div>
            <div class="activity_statistics">
                <div class="diamond_title flex-align-between">
                    <div class="placeholder"></div>
                    <div>活动参与情况统计</div>
                    <div class="placeholder">
                        <img src="../../assets/datascreen/export.png" alt="" @click="statistics_Excel">
                        <span @click="statistics_Excel"> 导出数据</span>
                    </div>
                </div>
                <div class="activity_statistics_details">
                    <div class="details_top flex-align-evenly">
                        <div class="activity_theme flex-column-center">
                            <div class="activity_theme_name">书吉语贺新春活动</div>
                            <img src="../../assets/datascreen/hd.jpg" alt="">
                        </div>
                        <div class="specific_number">
                            <div class="specific_number_sel">
                                <!-- <el-select v-model="value" placeholder="请选择" size="mini">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select> -->
                            </div>
                            <div class="specific_proportion flex-align-evenly">
                                <div class="flex-column-center">
                                    <span>参与人数</span>
                                    <span>{{ participantNum }}</span>
                                    <span>占全校的{{ participant_ratio }}%</span>
                                </div>
                                <div class="flex-column-center">
                                    <span>作品总数</span>
                                    <span>{{ worksNum }}</span>
                                    <span>占全校的{{ worksNum_ratio }}%</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="details_Bottom flex-align-between">
                        <div class="details_Bottom_box">
                            <div class="title flex-align-center">活动作品案例</div>
                            <div class="details_carousel flex-align-evenly" v-if="work_case.length != 0">
                                <div class="carousel_dev flex-align-center">
                                    <i class="el-icon-arrow-left" @click="carousel_dev"></i>
                                </div>
                                <div class="carousel_c">
                                    <el-carousel trigger="click" :loop="false" indicator-position="none" :autoplay="false"
                                        ref="carouselRef">
                                        <el-carousel-item v-for="(item, index) in work_case" :key="index">
                                            <div class="work_exhibition_box">
                                                <div class="work_img_box flex-align-center">
                                                    <img class="work_img" :src="item.fileUrl" alt=""
                                                        @click="giveImg(item.fileUrl)">
                                                    <img class="work_img_icon" :src="workgradeImg[workgrade(item.score)]"
                                                        alt="">
                                                </div>
                                                <div class="work_name">{{ item.studentName }}</div>
                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>
                                </div>
                                <div class="carousel_next flex-align-center">
                                    <i class="el-icon-arrow-right" @click="carousel_next"></i>
                                </div>
                            </div>
                            <div class="carousel_empty flex-align-center" v-else>
                                <!-- 暂无数据 -->
                                <PlaceholderScreen></PlaceholderScreen>
                            </div>
                        </div>
                        <div class="wire"></div>
                        <div class="details_Bottom_box">
                            <div class="title flex-align-center">参与学生</div>
                            <div class="details_list" v-if="participantStudents.length != 0">
                                <div class="details_list_box" v-for="(item, index) in participantStudents" :key="index">
                                    <span> {{ index + 1 }}、</span>
                                    <span> {{ item.name }}</span>
                                    <span>活动作品：</span>
                                    <span>{{ item.num }}个</span>
                                </div>
                            </div>
                            <div class="no_data flex-align-center" v-else>
                                <!-- 暂无数据 -->
                                <PlaceholderScreen></PlaceholderScreen>
                            </div>
                        </div>
                        <div class="wire"></div>
                        <div class="details_Bottom_box">
                            <div class="title flex-align-center">未参与学生</div>
                            <div class="details_list" v-if="nonParticipantStudents.length != 0">
                                <div class="details_list_box nonParticipantStudents flex-align-center "
                                    v-for="(item, index) in nonParticipantStudents" :key="index">
                                    <span> {{ index + 1 }}、</span>
                                    <span> {{ item.name }}</span>
                                </div>
                            </div>
                            <div class="no_data flex-align-center" v-else>
                                <!-- 暂无数据 -->
                                <PlaceholderScreen></PlaceholderScreen>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="time_distribution">
                <div class="diamond_title flex-align-center">学生应用时间分布</div>
                <div class="time_distribution_graphic flex-align-center">
                    <div class="graphic_son" id="time_distribution_graphic" v-if="time_distribution_Show"></div>
                    <PlaceholderScreen v-else></PlaceholderScreen>
                </div>
            </div>
        </div>
        <div class="layout_R">
            <div class="Application_ranking">
                <div class="diamond_title flex-align-between">
                    <div class="placeholder"></div>
                    <div>学生app应用排名</div>
                    <div class="placeholder">
                        <img src="../../assets/datascreen/export.png" alt="" @click="ranking_Excel">
                        <span @click="ranking_Excel"> 导出数据</span>
                    </div>
                </div>
                <div class="Application_ranking_graphic">
                    <div class="Application_list_head Application_list_head_bg">
                        <div class="ranking flex-align-center">排名</div>
                        <div class="stu_name flex-align-center">学生姓名</div>
                        <div class="course_num flex-align-center">APP学习课程数</div>
                        <div class="work_num flex-align-center">测评作品总数</div>
                    </div>
                    <div class="Application_list_box" v-if="Application_list.length != 0">
                        <div class="Application_list_head Application_list" v-for="(item, index) in Application_list"
                            :key="index">
                            <div class="ranking flex-align-center">{{ item.sort }}</div>
                            <div class="stu_name flex-align-center">{{ item.uname }}</div>
                            <div class="course_num flex-align-center">{{ item.appNum }}</div>
                            <div class="work_num flex-align-center">{{ item.workNum }}</div>
                        </div>
                    </div>
                    <div class="no_data flex-align-center" v-else>
                        <!-- 暂无数据 -->
                        <PlaceholderScreen></PlaceholderScreen>
                    </div>
                </div>
            </div>
            <div class="stu_work">
                <div class="diamond_title flex-align-center">学生上传作品动态</div>
                <div class="stu_work_sel">
                    <el-select v-model="value" placeholder="请选择" size="mini" @change="worktype"
                        :popper-append-to-body="false">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="work_list" v-if="workDynamics_List.length != 0">
                    <div class="work_each" v-for="(item, index) in workDynamics_List" :key="index">
                        <div class="work_synopsis">
                            <span>{{ item.createTime }}</span>
                            <span>{{ item.className }}</span>
                            <span>{{ item.studentName }}</span>
                        </div>
                        <div class="work_exhibition ">
                            <div class="work_exhibition_box">
                                <div class="work_img_box flex-align-center">
                                    <img class="work_img" :src="item.fileUrl" alt="" @click="giveImg(item.fileUrl)">
                                    <img class="work_img_icon" :src="workgradeImg[workgrade(item.score)]" alt="">
                                </div>
                                <div class="work_name">{{ item.studentName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="no_data flex-align-center" v-else>
                    <PlaceholderScreen></PlaceholderScreen>
                </div>
                <div class="work_pagination flex-align-center">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                        :current-page.sync="currentPage1" :page-size="10" layout="total, prev, pager, next" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <div class="appraisalbox">
            <el-dialog :visible.sync="centerDialogVisible" :modal-append-to-body="false" :append-to-body="false"
                :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" center>
                <appraisal :word="dialog_words" ref="appraisal"></appraisal>
                <img class="close" src="../../assets/datascreen/close.png" @click="close" />
            </el-dialog>
        </div>
    </div>
</template>

<script>
import PlaceholderScreen from '../DataScreen/placeholderBox/placeholderScreen.vue';
import axios from "axios";
import appraisal from "../../Common_components/appraisals/appraisal";
export default {
    //import引入组件才能使用
    components: {
        PlaceholderScreen,
        appraisal
    },
    props: ['termKey_value', 'classId_value', 'class_Name'],
    data() {
        return {
            centerDialogVisible: false,
            // 放大图片
            dialog_words: '',
            //整体数据
            // 参与人数
            attend: 0,
            // 学习课程
            studyCourse: 0,
            // 测评作品
            evaluationWork: 0,
            // 未参与人数
            nonAttend: 0,
            //活动参与情况统计筛选
            options: [{
                value: '1',
                label: '同步课测评作品'
            }, {
                value: '2',
                label: '体系课测评作品'
            }],
            //活动参与情况统计选择
            value: '1',
            //活动参与情况统计
            //参与学生
            participantStudents: [],
            //未参与学生
            nonParticipantStudents: [],
            //参与人数
            participantNum: 0,
            //参与人数占比
            participant_ratio: 0,
            //参与作品
            worksNum: 0,
            //参与作品占比
            worksNum_ratio: 0,
            //活动作品案例
            work_case: [],
            //活动作品案例总数
            work_total: 0,
            //活动作品案例总数
            work_pages: 1,
            //学生app应用排名
            Application_list: [],
            //作品当前分页
            currentPage1: 1,
            //作品总数
            total: 0,
            //作品列表
            workDynamics_List: [],
            //下载
            isclick: true,
            //班级id
            classId: '',
            //学期id
            termKey: '',
            //暂无数据
            stu_App_graphicShow1: true,
            stu_App_graphicShow2: true,
            Top10_Show: true,
            time_distribution_Show: true,
            className: '',
            //作品等级
            workgradeImg: [require('../../assets/datascreen/Particularlyexcellent.png'), require('../../assets/datascreen/excellent.png'), require('../../assets/datascreen/fine.png'), require('../../assets/datascreen/require_elevation.png')]
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        // 学生本学期自学课程情况
        async getselfStudySituation() {
            let data = {
                classId: this.classId,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getselfStudySituation(data);
            let arr1 = resData.data.appNum;
            let arr2 = resData.data.workNum;
            const allZeros1 = arr1.every(item => item == 0);
            const allZeros2 = arr2.every(item => item == 0);
            allZeros1 && allZeros2 ? this.stu_App_graphicShow1 = false : this.stu_App_graphicShow1 = true;
            if (allZeros1 && allZeros2) return;

            let value1 = [];
            let value2 = [];
            value1.push(resData.data.appNum[0])
            value1.push(resData.data.workNum[0])
            value2.push(resData.data.appNum[1])
            value2.push(resData.data.workNum[1])
            let that = this;
            this.$nextTick(() => {
                that.stu_App_graphic1(value1, value2);
            })
        },
        // 学生本学期APP学习参与率
        async getstudyRate() {
            let data = {
                classId: this.classId,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getstudyRate(data);
            resData.data.schoolRate == 0 && resData.data.classRate == 0 ? this.stu_App_graphicShow2 = false : this.stu_App_graphicShow2 = true;
            if (resData.data.schoolRate == 0 && resData.data.classRate == 0) return;
            let value1 = [resData.data.schoolRate];
            let value2 = [resData.data.classRate];
            let that = this;
            this.$nextTick(() => {
                that.stu_App_graphic2(value1, value2);
            })
        },
        // 观看最多的课程Top10
        async getTop10() {
            let data = {
                classId: this.classId,
                termKey: this.termKey,
            };
            let value1 = [];
            let value2 = [];
            let resData = await this.$Api.DataScreen.getTop10(data);
            resData.data.length == 0 ? this.Top10_Show = false : this.Top10_Show = true;
            if (resData.data.length == 0) return;
            for (let index = 0; index < resData.data.length; index++) {
                value1.push(resData.data[index].title);
                value2.push(resData.data[index].num);
            }
            let that = this;
            this.$nextTick(() => {
                that.watched_Top_graphic(value1, value2);
            })
        },
        // 学生应用时间分布
        async getstudentTimeDistribution() {
            let data = {
                classId: this.classId,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getstudentTimeDistribution(data);
            let value1 = resData.data.class;
            let value2 = resData.data.school;
            let that = this;
            this.$nextTick(() => {
                that.time_distribution_graphic(value1, value2);
            })
        },
        // 整体数据
        async getaggregateData() {
            let data = {
                classId: this.classId,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getaggregateData(data);
            // 参与人数
            this.attend = resData.data.attend;
            // 学习课程
            this.studyCourse = resData.data.studyCourse;
            // 测评作品
            this.evaluationWork = resData.data.evaluationWork;
            // 未参与人数
            this.nonAttend = resData.data.nonAttend;
        },
        // 学生app应用排名
        async getstudentAPPLearnRank() {
            let data = {
                classId: this.classId,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getstudentAPPLearnRank(data);
            this.Application_list = resData.data;
        },
        // 学生上传作品动态 分页
        async getworkDynamics() {
            let data = {
                classId: this.classId,
                termKey: this.termKey,
                size: '10',
                current: this.currentPage1,
                workType: this.value,
            };
            let resData = await this.$Api.DataScreen.getworkDynamics(data);
            this.workDynamics_List = resData.data.records;
            this.total = Number(resData.data.total);
        },
        // 书吉语贺新春活动
        async getactivityParticipation() {

            let data = {
                classId: this.classId,
                termKey: this.termKey,
            };
            let resData = await this.$Api.DataScreen.getactivityParticipation(data);
            //未参与学生
            this.nonParticipantStudents = resData.data.nonParticipantStudents;
            //参与学生
            this.participantStudents = resData.data.participantStudents;

            this.participantNum = resData.data.participantNum;
            this.participant_ratio = resData.data.peopleNumWholeSchool == 0 ? 0 : this.toPercent(resData.data.participantNum, resData.data.peopleNumWholeSchool);
            this.worksNum = resData.data.worksNum;
            this.worksNum_ratio = resData.data.worksNumWholeSchool == 0 ? 0 : this.toPercent(resData.data.worksNum, resData.data.worksNumWholeSchool);
        },
        // 书吉语贺新春活动
        async getactivityParticipationImg() {

            let data = {
                classId: this.classId,
                // classId: 4178,
                size: 10,
                current: this.work_pages,
            };
            let resData = await this.$Api.DataScreen.getactivityParticipationImg(data);
            this.work_case = resData.data.records;
            this.work_total = resData.data.pages;
        },
        //导出书吉语贺新春活动参与统计Excel
        statistics_Excel() {
            let datas = {
                classId: this.classId,
            };
            this.$Api.DataScreen.getexportNewYearActivities(datas)
                .then(res => {
                    const link = document.createElement('a')
                    const blob = new Blob([res], {
                        type: 'application/x-msdownload'
                    });
                    const fileName = '活动参与情况统计报告';
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.download = fileName + '.xlsx';
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
        //导出学生app应用排名Excel
        ranking_Excel() {
            let datas = {
                classId: this.classId,
                termKey: this.termKey,
            };
            this.$Api.DataScreen.getexportStudentAPPLearnRank(datas)
                .then(res => {
                    const link = document.createElement('a')
                    const blob = new Blob([res], {
                        type: 'application/x-msdownload'
                    });
                    const fileName = '学生app应用排名报告';
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    document.body.appendChild(link);
                    link.download = fileName + '.xlsx';
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
        // 学生本学期自学课程情况
        stu_App_graphic1(value1, value2) {

            let that = this;
            let myChart = this.$echarts.init(document.getElementById("stu_App_graphic1"));

            myChart.setOption({
                title: {
                    text: '学生本学期自学课程情况',
                    left: '31%',
                    top: '2%',
                    textStyle: {
                        color: '#55C1FF',
                        fontSize: this.graphicRatio(14),
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}<br />{a0}: {c0}<br />{a1}: {c1}',
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                    },
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                legend: {
                    top: '12%',
                    itemGap: 10,
                    itemWidth: this.graphicRatio(15),
                    itemHeight: this.graphicRatio(8),
                    responsive: true,
                    textStyle: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12)
                    },
                },
                grid: {
                    top: '28%',
                    left: '15%',
                    right: '5%',
                    bottom: '14%',
                },
                xAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#2a6eb7'
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            fontSize: this.graphicRatio(12),
                            color: '#fff'
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#133261'
                            }
                        },
                    },

                ],
                yAxis: [
                    {
                        type: 'category',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3486DA'
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            fontSize: this.graphicRatio(12),
                            color: '#fff'
                        },
                        splitLine: {
                            show: false,
                        },
                        data: ['课程学习', '测评作品']
                    },
                ],
                series: [

                    {
                        name: '全校平均',
                        type: 'bar',
                        barWidth: this.graphicRatio(13),
                        itemStyle: {
                            color: new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, //变化度
                                //三种由深及浅的颜色
                                [{
                                    offset: 0,
                                    color: 'rgba(255, 133, 0,1)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(255, 191, 0,1)'
                                }]),
                        },
                        data: value1
                    },
                    {
                        name: that.className,
                        type: 'bar',
                        barWidth: this.graphicRatio(13),
                        itemStyle: {
                            color: new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, //变化度
                                //三种由深及浅的颜色
                                [{
                                    offset: 0,
                                    color: 'rgba(1, 106, 233,1)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(0, 196, 254,1)'
                                }]),
                        },
                        data: value2
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        // 学生本学期APP学习参与率
        stu_App_graphic2(value1, value2) {
            this.$echarts.init(document.getElementById("stu_App_graphic2")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("stu_App_graphic2"));

            myChart.setOption({
                title: {
                    text: '学生本学期APP学习参与率',
                    left: '31%',
                    top: '2%',
                    textStyle: {
                        color: '#55C1FF',
                        fontSize: this.graphicRatio(14),
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}<br />{a0}: {c0}%<br />{a1}: {c1}%',
                    // formatter:'{b}:{c}%',
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                    },
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                legend: {
                    top: '12%',
                    itemGap: 10,
                    itemWidth: this.graphicRatio(15),
                    itemHeight: this.graphicRatio(8),
                    responsive: true,
                    textStyle: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12)
                    },
                },
                grid: {
                    top: '24%',
                    left: '15%',
                    right: '5%',
                    bottom: '20%',
                },
                xAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#2a6eb7'
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            fontSize: this.graphicRatio(12),
                            color: '#fff'
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#133261'
                            }
                        },
                    },

                ],
                yAxis: [
                    {
                        type: 'category',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3486DA'
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            fontSize: this.graphicRatio(12),
                            color: '#fff'
                        },
                        splitLine: {
                            show: false,
                        },
                        data: ['参与率']
                    },
                ],
                series: [

                    {
                        name: '全校平均',
                        type: 'bar',
                        barWidth: '13',
                        itemStyle: {
                            color: new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, //变化度
                                //三种由深及浅的颜色
                                [{
                                    offset: 0,
                                    color: 'rgba(22, 186, 137,1)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(232, 237, 112,1)'
                                }]),

                        },
                        data: value1
                    },
                    {
                        name: that.className,
                        type: 'bar',
                        barWidth: '13',
                        itemStyle: {
                            color: new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, //变化度
                                //三种由深及浅的颜色
                                [{
                                    offset: 0,
                                    color: 'rgba(254, 107, 142,1)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(250, 194, 141,1)'
                                }]),
                        },
                        data: value2
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //观看最多的课程Top10
        watched_Top_graphic(value1, value2) {
            this.$echarts.init(document.getElementById("watched_Top_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("watched_Top_graphic"));

            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                grid: {
                    top: '5%',
                    left: '25%',
                    right: '5%',
                    bottom: '14%',
                },
                xAxis: [
                    {
                        type: 'value',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#2a6eb7'
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            fontSize: this.graphicRatio(12),
                            color: '#fff'
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#133261'
                            }
                        },
                    },

                ],
                yAxis: [
                    {
                        type: 'category',
                        inverse: true,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#3486DA'
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLabel: {
                            show: true,
                            fontSize: this.graphicRatio(12),
                            color: '#fff',
                            formatter: function (value) {
                                if (value.length > 9) {
                                    return `${value.slice(0, 9)}...`;
                                }
                                return value;
                            }
                        },
                        splitLine: {
                            show: false,
                        },
                        data: value1
                    },
                ],
                series: [

                    {
                        name: '观看人次',
                        type: 'bar',
                        barWidth: this.graphicRatio(13),
                        itemStyle: {
                            color: new that.$echarts.graphic.LinearGradient(0, 0, 1, 0, //变化度
                                //三种由深及浅的颜色
                                [{
                                    offset: 0,
                                    color: 'rgba(255, 217, 165,1)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(221, 223, 43,1)'
                                }]),
                        },
                        label: {
                            fontSize: this.graphicRatio(12),
                        },
                        data: value2
                    },
                ],
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //学生应用时间分布
        time_distribution_graphic(value1, value2) {
            this.$echarts.init(document.getElementById("time_distribution_graphic")).dispose();
            let that = this;
            let myChart = this.$echarts.init(document.getElementById("time_distribution_graphic"));

            myChart.setOption({
                legend: {
                    top: '5%',
                    itemGap: 10,
                    icon: 'rect',
                    itemWidth: this.graphicRatio(25),
                    itemHeight: this.graphicRatio(2),
                    textStyle: {
                        color: '#fff',
                        fontSize: this.graphicRatio(12)
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}<br />{a0}: {c0}<br />{a1}: {c1}',
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                grid: {
                    top: '18%',
                    left: '3%',
                    right: '4%',
                    bottom: '8%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#2a6eb7'
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        fontSize: this.graphicRatio(12),
                        color: '#fff'
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#133261'
                        }
                    },
                    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    data: ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#2a6eb7'
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        fontSize: this.graphicRatio(12),
                        color: '#fff'
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#133261'
                        }
                    },
                },
                series: [
                    {
                        name: that.className,
                        type: 'line',
                        // stack: 'none',
                        itemStyle: {
                            color: '#f5c738',
                        },
                        showSymbol: false,
                        // data: [0, 0, 1, 134, 90, 230, 2]
                        data: value1
                    },
                    {
                        name: '全校',
                        type: 'line',
                        // stack: 'none',
                        itemStyle: {
                            color: '#33c7aa',
                        },
                        showSymbol: false,
                        // data: [0, 0, 1, 234, 290, 330, 2]
                        data: value2
                    },

                ]
            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        //切换作品类型
        worktype() {
            console.log(this.value);
            this.getworkDynamics()
        },
        //作品分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.currentPage1 = val;
            this.getworkDynamics();

        },
        //左右切换
        carousel_dev() {
            this.$refs.carouselRef.prev();
            if (this.work_pages > 1) {
                this.work_pages--;
            }
            this.getactivityParticipationImg()
        },
        carousel_next() {
            this.$refs.carouselRef.next();
            if (this.work_pages < this.work_total) {
                this.work_pages++;
            }
            this.getactivityParticipationImg()
        },
        //计算百分比
        toPercent(num, total) {
            return (Math.round(num / total * 10000) / 100.00);
        },
        //请求集合
        gather() {
            // 学生本学期自学课程情况
            this.getselfStudySituation();
            // 学生本学期APP学习参与率
            this.getstudyRate();
            // 观看最多的课程Top10
            this.getTop10();
            // 学生应用时间分布
            this.getstudentTimeDistribution();
            // 整体数据
            this.getaggregateData();
            //学生app应用排名
            this.getstudentAPPLearnRank();
            //学生上传作品动态 分页
            this.getworkDynamics();
            //书吉语贺新春活动
            this.getactivityParticipation();
            //书吉语贺新春活动作品图分页
            this.getactivityParticipationImg();
        },
        //接收数据参数
        receive(value1, value2, value3) {
            //班级id
            this.termKey = value1;
            this.classId = value2;
            this.className = value3;
            this.gather()
        },
        //弹窗
        close() {
            this.centerDialogVisible = false;
        },
        //作品放大
        giveImg(value) {
            this.centerDialogVisible = true;
            this.dialog_words = value;
        },
        //图片等级分类
        workgrade(value) {
            if (value == 0) {
                return 3
            } else if (value >= 60 && value < 80) {
                return 2
            } else if (value >= 80 && value < 90) {
                return 1
            } else if (value >= 90) {
                return 0
            }
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        this.receive(this.termKey_value, this.classId_value, this.class_Name)
    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style lang="less" scoped>
@import "./less/classData_stuUse.less";
</style>